/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;


/* Source: https://philipwalton.com/articles/what-no-one-told-you-about-z-index/ */
.opacity-position-hack {
  opacity: .99;
}

.legal {
  font-family: Arial;
}

.linear-progress-bar {
  transform: translateX(-100%);
}

@keyframes linear-progress {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
